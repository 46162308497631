import { gql } from "@apollo/client";
import { generalResponse } from "../constants";

export const SEND_EMAIL_VERIFICATION = gql`
  mutation SendVerificationEmail ($id: String!) {
    sendVerificationEmail (id: $id)
  }
`;

export const SEND_EMAIL_OTP = gql`
  mutation SendEmailOTP ($id: String!) {
    sendEmailOTP(id: $id) {
        ${generalResponse}
    }
  }
`;

export const VERIFY_EMAIL_OTP = gql`
  mutation VerifyEmailOTP ($id: String!, $otp: String!) {
    verifyEmailOTP (id: $id, otp: $otp)
  }
`