import { imageType } from "../constants"

export const basicRequirements = `
  id
  specId
  parentId
  number
  organizationId
  title
  content
  leafNode
  type
  pdfFile {
    ${imageType}
  }
`

export const requirements = `
  ${basicRequirements}
  pageNumber
  importance
  createdAt
  updatedAt
  llmAnalysis {
    projectTypes
    LOD
    designStages
    disciplines
    sectionKeywords
    requirementType
    designAreas
    responsibilityRoles
    buildingDivision
    standardCheck
    materialProperties {
      material
      corrosionResistance
      fireResistance
      color
      finishes
      insulationLayers
      standards
      tests
    }
    categoryProperties {
      subCategoryDisplayName
      length
      width
      height
      volume
      diameter
      thickness
      locations
      standards
      tests
    }
    sectionLeed {
      category
      credits
    }
    rooms
    summary
    systems
    tests {
      name
      appliedBy
      sendTo
      designStage
    }
    fluids {
      name
      container
    }
    enviromentalLoads
    allCategories {
      categoryId
      subCategoryId
      subCategoryDisplayName
    }
    allMaterials
    allStandards
  }
  imgFile {
    ${imageType}
  }
  parsedContent{
    type
    text
    imgFile{
      ${imageType}
    }
  }
`

export const specHighlights = `
highlights {
  matchedFilters
  matchedFields {
    categories
    materials
    standards
  }
}`