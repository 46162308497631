import { gql } from "@apollo/client";
import { generalResponse } from "../constants";

export const CREATE_HOUSE_LINK = gql`
  mutation createHouseLink($houseId: ID!, $externalHouseId: ID!) {
    createHouseLink(houseId: $houseId, externalHouseId: $externalHouseId) {
      ${generalResponse}
    }
  }
`

export const GET_HOUSE_LINK =  gql`
  query getHouseLinkByExternalHouseId($externalHouseId: ID!) {
      getHouseLinkByExternalHouseId(externalHouseId: $externalHouseId) {
          houseId
          externalHouseId
          createdBy
      }
  } 
`