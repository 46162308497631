import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { withAuth } from "../contexts/auth";
import { withTracking } from "../contexts/tracking";
import { Avatar, Popover } from "../../components";
import { useRecoilValue } from "recoil";
import { useResetAtom } from "jotai/utils";
import {
  allProductPropertiesState,
  organizationState,
  permissionsState,
  userFeaturesPermissionsState,
  userState,
} from "../../atoms";
import { setCookie } from "../../utils";
import { useAtomValue } from "jotai";
import { PAGE_ACCESS } from "../../constants/featuresAndPermissions";
import { useLogout } from "../../hooks";

const unitTypes = [{
  id: 'metric', displayName: 'Metric Units'
}, {
  id: 'imperial', displayName: 'Imperial Units'
}]

const Header = ({
  tracking,
  className,
  auth,
  isLeftMenuOpen,
  handleLeftMenuOpenCallback,
}) => {

  // local states
  const [sticky, setSticky] = useState(false);

  // hooks & ref
  const headerRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const logout = useLogout()

  // global states
  const { isVendor, isPlanner, id: orgId } = useRecoilValue(organizationState);
  const { organizations = [] } = useRecoilValue(userState);
  const resetAllProductPropertiesState = useResetAtom(allProductPropertiesState)
  const { pageAccess } = useAtomValue(userFeaturesPermissionsState);

  const { userRoles = [] } = useAtomValue(permissionsState);

  const makeHeaderSticky = debounce(() => {
    const scroll = window.pageYOffset;
    if (scroll >= 30 && !sticky) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, 5);

  useEffect(() => {
    window.addEventListener("scroll", makeHeaderSticky);
    return () => {
      window.removeEventListener("scroll", makeHeaderSticky);
    };
  }, [makeHeaderSticky]);

  const onLogout = useCallback(() => {
    logout()
  }, [logout]);

  const isMasterOrganization = useMemo(() => isPlanner || isVendor, [isPlanner, isVendor]);

  const onChangeOrganization = useCallback((id) => {
    resetAllProductPropertiesState();
    setCookie("__current_organization", id);
    history.push('/products')
    history.go(0)
  }, [history, resetAllProductPropertiesState]);

  return (
    <>
      <div
        className={`header ${className ? className : sticky ? "stick" : ""}`}
        ref={headerRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="menu-row">
                <div className="logo-wrap d-none d-lg-block">
                  <a href="https://www.bimmatch.com/">
                    <img alt="logo" src="/images/bimmatch/logo-header.svg" />
                  </a>
                </div>
                <div className="logo-wrap d-lg-none">
                  <a href="https://www.bimmatch.com/">
                    <img
                      alt="logo"
                      src="/images/bimmatch/Logo_Header_Mobile.svg"
                    />
                  </a>
                </div>
                <div className="menu-wrap">
                  <div className="menu-right">
                    <UnitsPopover history={history} />
                    {auth && auth.isAuthenticated && (
                      <>
                        {/* <div className="button-wrap">
                        <Notification num={5} />
                      </div> */}
                        <div className="button-wrap">
                          <div className="profile">
                            <Popover
                              content={
                                <ProfilePopoverContent
                                  onClickLogout={onLogout}
                                  onClickOrgAccount={() => {
                                    history.push("/settings/organization");
                                  }}
                                  onClickMyAccount={() => {
                                    history.push("/settings/personal");
                                  }}
                                  organizations={organizations}
                                  showOrganizationAccountTab={!!pageAccess?.[PAGE_ACCESS.ORGANIZATION_SETTINGS]}
                                  currentOrgId={orgId}
                                  userRoles={userRoles}
                                  onChangeOrganization={onChangeOrganization}
                                  isMasterOrganization={isMasterOrganization}
                                />
                              }
                              hideArrow={true}
                              placement="bottom"
                            >
                              <Avatar
                                url={auth?.user?.photoURL}
                                firstName={
                                  auth.user.displayName === null
                                    ? localStorage.getItem("username") === null
                                      ? auth.user.email.split("@")[0]
                                      : localStorage.getItem("username")
                                    : auth.user.displayName
                                }
                              />
                            </Popover>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="menu-left">
                    <div
                      id="nav_list"
                      className="menu-mobile-icon"
                      onClick={(e) =>
                        handleLeftMenuOpenCallback(!isLeftMenuOpen)
                      }
                    >
                      <div
                        id="toggle-icon"
                        className={isLeftMenuOpen ? "open" : ""}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className={`main-navigation pushmenu pushmenu-left ${isLeftMenuOpen ? "pushmenu-open" : ""}`}>
                      <nav id="nav" className="nav">
                        <ul className="main-menu-list">
                          <li key={"products"}>
                            <Link
                              to="/products"
                              onClick={() => {
                                tracking && tracking.track("Products List");
                              }}
                              className={location.pathname === "/products" ? "active" : ""}
                            >
                              PRODUCTS &amp; MATERIALS
                            </Link>
                          </li>

                          {allHeaderLinks.map(linkData => (
                            !!pageAccess?.[linkData.access] ?
                              <li key={linkData.path}>
                                <Link
                                  to={`/${linkData.path}`}
                                  className={location.pathname === `/${linkData.path}` ? "active" : ""}
                                  onClick={() => {
                                    tracking && tracking.track(linkData.trackName);
                                  }}
                                >
                                  {linkData.name}
                                </Link>
                              </li>
                              : <Fragment key={linkData.path}></Fragment>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const allHeaderLinks = [
  {
    path: "projects",
    access: PAGE_ACCESS.PROJECTS,
    name: "MY PROJECTS",
    trackName: "Poject List"
  },
  {
    path: "analytics",
    access: PAGE_ACCESS.ANALYTICS,
    name: "ANALYTICS",
    trackName: "Analytics"
  },
  {
    path: "specs",
    access: PAGE_ACCESS.SPECS,
    name: "SPECS",
    trackName: "Specs"
  },
  {
    path: "team",
    access: PAGE_ACCESS.TEAM,
    name: "TEAM",
    trackName: "Team"
  }, {
    path: "organization-items",
    access: PAGE_ACCESS.ORGANIZATION_DB,
    name: "ORGANIZATION DB",
    trackName: "Organization DB"
  },

]

const ProfilePopoverContent = ({
  onClickMyAccount,
  onClickOrgAccount,
  onClickLogout,
  showOrganizationAccountTab,
  organizations,
  currentOrgId,
  userRoles,
  onChangeOrganization,
  isMasterOrganization
}) => {

  const onClickItems = useCallback((event, type) => {
    event.stopPropagation();
    if (type === "my-account") {
      onClickMyAccount && onClickMyAccount();
    } else if (type === "org-account") {
      onClickOrgAccount && onClickOrgAccount();
    } else {
      onClickLogout && onClickLogout();
    }
  },
    [onClickMyAccount, onClickOrgAccount, onClickLogout]
  );

  const onClickOrganizationTab = useCallback((event, orgId) => {
    event.stopPropagation();
    if (currentOrgId !== orgId)
      onChangeOrganization && onChangeOrganization(orgId);
  },
    [currentOrgId, onChangeOrganization]
  );

  return (
    <div
      className="profile-popover-content"

    >
      <div onClick={(e) => onClickItems(e, "my-account")} className="profile-content-item">
        <img src={"/images/icon/user-account.svg"} alt="My account" />{" "}
        <span>My account</span>
      </div>

      <hr />

      <div className="organizations-list" >
        {organizations.map((organization) => (
          <div
            key={organization.id}
            className={`organization-details ${organization.id === currentOrgId
                ? "organization-details-active"
                : ""
              }`}
            onClick={(event) =>
              onClickOrganizationTab(event, organization.id, organization)
            }
          >
            <div className="organization-name">
              {organization?.displayName || (<span className='no-org-name'>{isMasterOrganization ? 'Missing organization name' : 'No organization'}</span>)}
            </div>
            {!!organization?.roles?.length && (
              <div className="organization-roles">
                {userRoles
                  .filter((role) => organization?.roles.includes(role.id))
                  .map((role) => (
                    <p key={role.id} className="organization-role-name">
                      {role?.displayName}
                    </p>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <hr />

      {showOrganizationAccountTab && (
        <>
          <div
            className="profile-content-item"
            onClick={(e) => onClickItems(e, "org-account")}
          >
            <img src={"/images/icon/suitcase.svg"} alt="Organization account" />{" "}
            <span>Organization account</span>
          </div>
          <hr />
        </>
      )}

      <div
        className="profile-content-item"
        onClick={(e) => onClickItems(e, "logout")}
      >
        <img src={"/images/icon/logout.svg"} alt="Log out" />{" "}
        <span>Log out</span>
      </div>
    </div>
  );
};

export const UnitsPopover = ({ history }) => {

  const defaultUnit = unitTypes.find(unitData => unitData.id === (localStorage.getItem('unit') || 'metric'))
  const [selectedUnit, setSelectedUnit] = useState(defaultUnit)

  const onSelectUnit = useCallback(unitData => {
    if (selectedUnit.id !== unitData.id) {
      setSelectedUnit(unitData)
      localStorage.setItem("unit", unitData.id);
      history.go(0)
    }
  }, [selectedUnit, history])

  return (
    <Popover
      content={<UnitsPopoverContent onSelectUnit={onSelectUnit} selectedUnit={selectedUnit.id} />}
      placement="bottom"
      className='units-popover'
    >
      <div className="unit-container">
        <img className="unit-icon" alt="unit" src={`/images/icon/${selectedUnit.id}-unit.svg`} /> <span className="unit-name">{selectedUnit.displayName}</span>
      </div>
    </Popover>
  )
}

export const UnitsPopoverContent = ({ selectedUnit, onSelectUnit }) => {
  return (
    <div className="units-list-container">
      {unitTypes.map(unitData => <div className={`single-unit-container ${selectedUnit === unitData.id ? 'selected-unit' : ''}`} key={unitData.id} onClick={() => onSelectUnit && onSelectUnit(unitData)}><img className="unit-icon" alt="unit" src={`/images/icon/${unitData.id}-unit.svg`} /> <span className="unit-name">{unitData.displayName}</span></div>)}
    </div>
  )

}

export default withTracking(withAuth(Header));
