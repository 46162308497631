import { gql } from "@apollo/client";

export const BOM_OBJECT_METADATA = gql`
  query GetBOMObjectMetadata($houseId: String!, $viewId: String!, $objectId: String!) {
    getBOMObjectMetadata(houseId: $houseId, viewId: $viewId, objectId: $objectId) {
        objectId
        categories
        materials
    }
  }
`