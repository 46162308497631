import { gql } from "@apollo/client";

export const GET_JOB_NOTIFICATIONS = gql`
    query GetJobNotifications {
        getJobNotifications {
            id
            organizationId
            status
            type
            metadata {
                itemsFailed
                itemsProcessed
                totalItems
            }
            createdAt
            createdBy
            updatedAt
        }
    }
`;

export const UPDATE_JOBS = gql`
    mutation UpdateJobs($input: [UpdateJobInput]!) {
        updateJobs(input: $input) {
            status
            message
            isNewUser
        }
    }
`;