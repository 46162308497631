import { atom } from "recoil";
import { atomWithReset } from "jotai/utils";
import { atom as jatom } from "jotai";
import { PAGE_ACCESS, PERMISSIONS } from "./constants/featuresAndPermissions";

//-------Auth Data----
export const autodeskAuthState = atom({
  key: "autodesk-auth",
  default: false,
});

export const authState = atomWithReset({
  user: null,
  isAuthenticated: false,
  isResetPasswordCodeAuthenticated: false
});

export const checkUserAuthState = atomWithReset(null);

export const hasCreatedNewUserAuthState = atomWithReset(false);

//Properties related data
export const permissionsState = jatom({
  userRoles: [],
  professions: [],
});

export const allProductPropertiesState = atomWithReset(null);

// ------UserData-----
export const userState = atom({
  key: "user",
  default: {
    organizations :[]
  },
});

// ------UserData-----
export const organizationState = atom({
  key: "organization",
  default: { id: "", isPremiumUser: false , usage: null },
});

// ------Plans-----
export const planDetailsState = atom({
  key: "planDetails",
  default: [],
});

// ------User features and permissions-----
function getDefaultValueForAccessAndPermissions(constantType, isForPermissions) {
  return Object.values(constantType).reduce((acc, keyName) => {
    acc[keyName] = false

    if (isForPermissions) {
      acc[keyName] = getDefaultValueForAccessAndPermissions(PERMISSIONS)
    }
    return acc
  }, {})
}

// for logged out users
export const userFeaturesPermissionsState = atomWithReset({
  pageWisePermissions: {
    ...getDefaultValueForAccessAndPermissions(PAGE_ACCESS, true)
  },
  pageAccess: {
    ...getDefaultValueForAccessAndPermissions(PAGE_ACCESS)
  }
});

// -----BOM------
export const bomSelectedCategoryNameState = atom({
  key: "bomSelectedCategoryName",
  default: undefined,
});

export const bomSelectedObject = atom({
  key: "bomSelectedObject",
  default: undefined,
});

export const forgeCategories = atom({
  key: "forgeCategories",
  default: {},
});

// ------Property Value Options-----
export const propertyValueOptionsState = atom({
  key: "propertyValueOptions",
  default: [],
});

// ---Product Related Data-------

export const productCategoriesState = atom({
  key: "productCategories",
  default: [],
});

export const productBrandsState = atom({
  key: "productBrands",
  default: [],
});

export const productDistributorsState = atom({
  key: "productDistributors",
  default: [],
});

// ---CMS-------
export const privateCatalogState = atom({
  key: "privateCatalog",
  default: {},
});

//Houses/Projects
export const housesState = jatom(null);
export const forgePropertyDataState = jatom({});
export const forgeRowDataState = jatom({});
export const forgeViewerState = jatom(undefined);
export const isForgeViewLoadingState = jatom(true);
export const forgeToolbarCreatedState = jatom(false);

// --- Org Items/DB ----
export const orgItemsMatchbarState = atomWithReset({});

// materials
export const materialListState = jatom([]);
export const sandboxDataState = jatom([]);
export const selectedMaterialState = jatom(undefined);

// specs
export const specsDetailState = jatom([]);