import React, { cloneElement, useContext, useEffect, useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "./common/contexts/auth";
import BaseLayout from "./common/layout/BaseLayout";
import { ConditionalWrapper } from "./components";
import PageLoader from "./components/page-loader";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { deleteCookie, setCookie } from "./utils";

const PrivateRoute = ({
  children,
  authAccess = true,
  withoutLayout = false,
  ...props
}) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  const { user, isAuthenticated, emailOTPRequired } = useContext(AuthContext);
  const isAuthorizedUser = useMemo(() => isAuthenticated && user?.emailVerified, [user, isAuthenticated])


  // Plugin
  // to save the data that plugin is openened
  useEffect(() => {
    if (location.pathname.includes('/plugin')) {
      const variables = location.pathname.split('/')
      
      setCookie('plugin', true)
      setCookie('pluginType', variables[2])
      setCookie('pluginId', variables[3])
    } else {
     ['plugin', 'pluginType', 'pluginId'].forEach(cookieName => {
      deleteCookie(cookieName)
     }) 
    }
}, [location, params])


  // checking if email otp required
  useEffect(() => {
    if (emailOTPRequired) {
      history.push("/protection")
    }  
  }, [emailOTPRequired, history])

  return (
    <Route
      {...props}
      render={(props) => (isAuthorizedUser && authAccess) ? (
        <React.Suspense fallback={<PageLoader />}>
          <ConditionalWrapper condition={!withoutLayout} Wrapper={<BaseLayout />}>
            {cloneElement(children, props)}
          </ConditionalWrapper>
        </React.Suspense>
      ) : (
        <Redirect
          to={{
            pathname: isAuthenticated ? "/verification" : "/signin",
          }}
        />
      )}
    />
  );
};

export default PrivateRoute;