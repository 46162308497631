import React from 'react';
import Popover from '../popover';


const PopoverInfo = ({ message, className, placement = 'right' }) => {
    return (
        <Popover
            showOnHover={true}
            content={<div className={className || ''}>{message}</div>}
            theme='black'
            placement={placement}
        >
            <img src={'/images/icon/info-circle.svg'} alt='info' />
        </Popover>
    )
}

export default PopoverInfo
