import { gql } from "@apollo/client";
import { countryType, geloLocationyType, imageType, mapLocationType } from "./graphql/constants";

const productPropertyType = `
  id
  label
  organizationId
  displaySection
  displayOrder
  type
  subType
  categoryIds
  icon
  extras {
    unit
  }
  validations {
    supportedFileTypes
    maxUploadFileSize
    maxUploadFileQuantity
    required
    max
    unselectable
    multiple
  }
`;

const propertySettings = `
  settings {
    lod
    standard
    instruction
    visibility
  }
`;

export const GET_PRODUCT_PROPERTIES = gql`
  query {
    getProductProperties {
      ${productPropertyType}
      
    }
  }
`;

export const GET_PRODUCT_PROPERTY_VARIANTS = gql`
  query {
    getProductPropertyVariants {
      ${productPropertyType}
      productPropertyId
      ${propertySettings}
    }
  }
`;

export const GET_TRANSLATIONS = gql`
  query ($organizationId: ID!) {
    getTranslations(organizationId: $organizationId) {
      id
      label
      displayName
      type
      organizationId
    }
  }
`;

export const GET_PROPERTY_VALUE_OPTIONS = gql`
  query {
    getPropertyValueOptions {
      id
      displayName
      propertyIds
      displaySection
      displayOrder
      organizationId
      additionalColumnValues {
        id
        value
      }
      metadata {
        key
        value
      }
    }
  }
`;

export const GET_DISPLAY_SECTIONS = gql`
  query {
    getDisplaySections {
      id
      displayName
      propertyIds
      categoryIds
      displayOrder
      organizationId
      type
      icon
      settings {
        addProperty
        manageVisibility
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query {
    categories {
      id
      displayName
      parentCategoryId
    }
  }
`;

export const GET_BRANDS = gql`
  query {
    brands {
      id
      displayName
    }
  }
`;

export const GET_DISTRIBUTORS = gql`
  query {
    distributors {
      id
      displayName
      country {
        ${countryType}
      }
    }
  }
`;

export const CREATE_PRODUCT_DETAIL = gql`
  mutation createProduct($input: ProductInput!) {
    createProduct(input: $input) {
      status
      message
      data {
        id
        organizationId
        description
        categories {
          id
          displayName
          parentCategoryId
        }
        brand {
          id
          displayName
        }
        distributors {
          id
          displayName
        }
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        properties {
          id
          value
          values
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT_DETAIL = gql`
  mutation updateProduct($id: ID!, $input: ProductInput!) {
    updateProduct(id: $id, input: $input) {
      status
      message
    }
  }
`;

export const GET_USER = gql`
  query {
    getUser {
      id
      displayName
      email
      organizations {
        id
        displayName
        roles
        houses {
          id
          displayName
        }
      }
      photoUrl
      marketing {
        bimmatch
        products
      }
      profession
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query {
    getOrganization {
      id
      displayName
      owner
      websiteUrl
      brandId
      subscription {
        plan
      }
      logo {
        ${imageType}
      }
      contact {
        name
        email
        phone
        role
      }
      address
      members {
        id
        roles
      }

      mapLocation {
        ${mapLocationType}
      }

      geoLocation {
        ${geloLocationyType}
      }
      otherOrganizationHouses {
        organizationDisplayName
        house {
          id
          displayName
        }
      }
      accDetails {
        userId
      }
      usage {
        accProjects
        bimProjects
        products
        rfaUploads
        rvtUploads
      specs
      }  
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    updateProduct(id: $id, input: { status: ARCHIVE }) {
      data {
        id
        displayName
        status
      }
    }
  }
`;

export const DUPLICATE_PRODUCT = gql`
  mutation duplicateProduct($id: ID!) {
    duplicateProduct(id: $id) {
      data {
        id
        displayName
        status
        categories {
          parentCategoryId
          id
          displayName
        }
      }
    }
  }
`;

export const SEARCH_PRIVATE_CATALG = gql`
  query getPrivateCatalogProducts($organizationIds: [ID]) {
    getPrivateCatalogProducts(organizationIds: $organizationIds) {
      id
      categories {
        displayName
        parentCategoryId
      }
      displayName
      status
      createdAt
      updatedAt
    }
  }
`;

export const GET_ORGANIZATIONS_PRODUCT = gql`
  query ($query: ElasticQuery) {
    allProductsCursor(elasticQuery: $query) {
      edges {
        node {
          id
          categories {
            displayName
          }
          displayName
          status
          createdAt
        }
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($id: ID!, $path: String, $files: [Upload]!) {
    uploadFile(id: $id, path: $path, files: $files) {
      ${imageType}
    }
  }
`;

export const CREATE_PRODUCT_PROPERTY = gql`
  mutation CreateProductProperty($input: [PropertyInput]!) {
    createProductProperty(input: $input) {
      ${productPropertyType}
    }
  }
`;

export const UPDATE_PRODUCT_PROPERTY = gql`
  mutation UpdateProductProperty($input: [PropertyInput]) {
    updateProductProperty(input: $input) {
      ${productPropertyType}
      productPropertyId
      ${propertySettings}
    }
  }
`;

export const DELETE_PRODUCT_PROPERTY = gql`
  mutation DeleteProductProperty($id: ID!) {
    deleteProductProperty(id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_PROPERTY_VALUE_OPTION = gql`
  mutation UpdatePropertyValueOption($input: [PropertyValueOptionInput]!) {
    updatePropertyValueOption(input: $input) {
      status
      message
    }
  }
`;

export const DELETE_PROPERTY_VALUE_OPTION = gql`
  mutation DeletePropertyValueOption($id: ID!) {
    deletePropertyValueOption(id: $id) {
      status
      message
    }
  }
`;

export const CREATE_DISTRIBUTOR_DETAIL = gql`
  mutation createDistributor($input: [DistributorInput]!) {
    createDistributor(input: $input) {
      id
      displayName
      mapLocation {
        ${mapLocationType}
      }
      geoLocation {
        ${geloLocationyType}
      }
      country {
        ${countryType}
      }
      contact {
        email
        phone
        address
      }
    }
  }
`;

export const SEND_RFQ_EMAIL = gql`
  mutation SendRequestForQuote($input: RequestForQuoteInput!) {
    sendRequestForQuote(input: $input) {
      status
      message
    }
  }
`;

const commonGetProdQuery = `
      id
      displayName
      description
      images {${imageType}}
      files {
        id
        values {${imageType}}
      }
      properties {
        id
        value
        values
      }
`;

export const GET_PRODUCT_DETAIL = gql`
  query ($id: ID!) {
    getProduct(id: $id) {
      ${commonGetProdQuery}
      organizationId
      organizationName
      organizationPlan
      organizationBrandId
      source
      mainCategory
      houses
      organizationLogo {
        ${imageType}
      }
      types {
        ${commonGetProdQuery}
        createdAt
      }
      status
      distributors {
        id
        displayName
        mapLocation {
          lat
          lng
        }
        contact {
          address
        }
      }
      categories {
        id
        displayName
        parentCategoryId
      }
      brand {
        id
        displayName
      }
      updatedAt
      createdAt
      types {
        id
        displayName
        description
        properties {
          id
          value
          values
        }
        files {
          id
          values {
            ${imageType}
          }
        }
        images {
          ${imageType}
        }
      }
    }
  }
`;

export const GET_PLANNER_REPORT_DATA = gql`
  query appliedProductReport(
    $events: [String]
    $from: String!
    $projectID: String
  ) {
    mixpanelEvents(from: $from, projectID: $projectID, events: $events) {
      id
      event
      chartTS
      mp_country_code
      mp_region
      mp_city
      projectID {
        id
        displayName
      }
      productID {
        id
        displayName
      }
      userID {
        id
        displayName
      }
      mp_mp_api_timestamp_ms
    }
  }
`;

export const GET_VENDOR_REPORT_DATA = gql`
  query appliedProductReport(
    $events: [String]
    $from: String!
    $organizationID: String
  ) {
    mixpanelEvents(
      from: $from
      organizationID: $organizationID
      events: $events
    ) {
      id
      event
      chartTS
      mp_country_code
      mp_region
      mp_city
      projectID {
        id
        displayName
      }
      productID {
        id
        displayName
      }
      userID {
        id
        displayName
      }
      mp_mp_api_timestamp_ms
    }
  }
`;

export const UPDATE_ORGANIZATION_DATA = gql`
  mutation updateOrganization($id: ID!, $input: OrganizationInput!) {
    updateOrganization(id: $id, input: $input) {
      status
      message
    }
  }
`;

export const CREATE_RFA_JOBS = gql`
  mutation createRFAJob(
    $id: ID!
    $jobId: ID!
    $type: JobType!
    $files: [FileLinkInput]!
  ) {
    createRFAJob(id: $id, jobId: $jobId, type: $type, files: $files) {
      status
      message
    }
  }
`;

export const CHECK_USER = gql`
  mutation ($email: String!, $logInMode: String!) {
    checkUser(email: $email, logInMode: $logInMode) {
      status
      message
      isNewUser
      emailOTPVerificationRequired
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

//Members APIs

export const GET_TEAM_DETAILS = gql`
  query {
    getTeamDetails {
      houses {
        id
        displayName
      }
      owner {
        id
        name
        email
        photoUrl
        joinedAt
        houses
      }

      invitedMembers
      members {
        id
        name
        email
        photoUrl
        joinedAt
        houses
        roles
      }
    }
  }
`;

// ---- Personal Profile ------
export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      status
      message
    }
  }
`;

//--- Team or project invitation ---
export const INVITE_MEMBER = gql`
  mutation InviteMember($email: String!, $type: String!, $houseId: ID) {
    inviteMember(email: $email, type: $type, houseId: $houseId) {
      status
      message
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation AddMember($token: String!) {
    addMember(token: $token) {
      status
      message
      houseId
      userOrganization {
        id
        displayName
        roles
        subscription {
          plan
        }
      }
    }
  }
`;

export const REMOVE_MEMBER = gql`
  mutation RemoveMember(
    $memberId: String
    $type: String!
    $email: String
    $houseId: String
  ) {
    removeMember(
      memberId: $memberId
      type: $type
      email: $email
      houseId: $houseId
    ) {
      status
      message
    }
  }
`;

export const ADD_ACCESS_TO_ORG_LIB = gql`
  mutation addAccessToOrgnizationLib($houseId: String!) {
    addAccessToOrgnizationLib(houseId: $houseId) {
      status
      message
    }
  }
`;

export const REMOVE_ACCESS_FROM_ORG_LIB = gql`
  mutation removeAccessToOrgnizationLib(
    $houseId: String!
    $organizationId: String
  ) {
    removeAccessToOrgnizationLib(
      houseId: $houseId
      organizationId: $organizationId
    ) {
      status
      message
    }
  }
`;

export const UPDATE_TEAM_DETAILS = gql`
  mutation UpdateTeamDetails($input: [MemberInput]!) {
    updateTeamDetails(input: $input) {
      status
      message
    }
  }
`;

//--- XXX ---

export const CREATE_PROJECT = gql`
  mutation CreateHouse($input: HouseInput!) {
    createHouse(input: $input) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateHouse($id: ID!, $input: HouseInput!) {
    updateHouse(id: $id, input: $input) {
      id
      displayName
      location
      country {
       ${countryType}
      }
      images {
        location
        displayName
        type
        Key
      }
      createdAt
      typeOfProject
      matchEngineSettings {
        locations
        standards
      }
    }
  }
`;

export const GET_HUBS = gql`
  query {
    getHubs {
      id
      displayName
      region
      type
    }
  }
`;

export const GET_ACC_ACCOUNT_PROJECTS = gql`
  query ($hubId: String!) {
    getProjects(hubId: $hubId) {
      id
      displayName
      type
    }
  }
`;

export const GET_ACC_FOLDER_CONTENTS = gql`
  query GetFolderContents(
    $hubId: String!
    $projectId: String!
    $folderId: String
  ) {
    getFolderContents(
      hubId: $hubId
      projectId: $projectId
      folderId: $folderId
    ) {
      displayName
      id
      type
    }
  }
`;

export const GET_REVIT_FILE_VERSION = gql`
  query Query($projectId: String!, $fileId: String!) {
    getRevitFileVersion(projectId: $projectId, fileId: $fileId) {
      cloudModelGuid
      cloudProjectGuid
      model
      projectType
    }
  }
`;

export const SYNC_ACC_PROJECT = gql`
  mutation SyncAccProject($houseId: String!) {
    syncAccProject(houseId: $houseId) {
      status
      message
    }
  }
`;

export const CONNECT_TO_ACC = gql`
  mutation ConnectToAcc($token: String!) {
    connectToAcc(token: $token) {
      status
      message
    }
  }
`;

export const SEARCH_PRIVATE_CATALOG_QUERY = gql`
  query SearchPrivateCatalogProducts(
    $from: Int
    $size: Int
    $input: SearchInput
  ) {
    searchPrivateCatalogProducts(from: $from, size: $size, input: $input) {
      hits {
        total
        results {
          id
          houses
          displayName
          images {
            ${imageType}
          }
          categories {
            id
            displayName
            parentCategoryId
          }
          brand {
            id
            displayName
          }
          properties {
            id
            value
            values
          }
          sustainabilityRanks {
            environmental
            economic
            social
          }
          updatedAt
          status
        }
      }
    }
  }
`;

export const GET_BOM_DETAIL = gql`
  query ($id: ID!, $viewId: String, $at: Int) {
    getBOM(id: $id, viewId: $viewId, at: $at) {
      objectid
      name
      category
      items
      properties {
        displaySection
        label
        value
      }
      objects {
        objectid
        name
        category
        items
        properties {
          displaySection
          label
          value
        }
        objects {
          objectid
          name
          category
          items
          properties {
            displaySection
            label
            value
          }
          objects {
            objectid
            name
            category
            items
            properties {
              displaySection
              label
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_OBJECT_HISTORY = gql`
  query ($houseId: String!, $objectId: Int!) {
    getObjectHistory(houseId: $houseId, objectId: $objectId) {
      createdAt
      action
      productId
      userId
      productName
      userName
      productImg
      userProfileImg
      objectId
    }
  }
`;

export const GET_HOUSES = gql`
  query GetHouses($after: String, $first: Int) {
    getHouses(after: $after, first: $first) {
      totalCount
      edges {
        node {
          id
          displayName
          demo
          images {
            location
            displayName
            type
            Key
          }
          createdBy {
            id
            photoUrl
            displayName
          }
          architect {
            name
          }
          team {
            id
            displayName
            photoUrl
          }
          createdAt
          updatedAt
          source
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_ALL_HOUSES = gql`
  query {
    getAllHouses {
      id
      displayName
      organizationId
      location
      description
    }
  }
`;

export const GET_HOUSE_VIEWS = gql`
  query GetHouseViews($houseId: String!) {
    getHouseViews(houseId: $houseId) {
      id
      house
      viewGuid
      displayName
      updatedAt
      createdAt
      bom {
        bomLoc {
          Bucket
          ETag
          Key
        }
        status
      }
    }
  }
`;

export const GET_COMPOUND_LAYER = gql`
  query GetCompoundLayer($id: ID!) {
    getCompoundLayer(id: $id) {
      category
      elementid
      uniqueId
      layers {
        function
        material
        thickness
        volume
      }
    }
  }
`;