import { gql } from "@apollo/client";
import { basicRequirements, specHighlights, requirements } from "./constants";

export const SEARCH_SPECS = gql`
  query SearchSpecs($houseId: String!, $input: SearchSpecRequirementsInput, $filters: SearchSpecRequirementsFilters) {
    searchSpecs(houseId: $houseId, input: $input, filters: $filters) {
        data {
          ${basicRequirements}

          highlights {
            matchedFilters
            matchedFields {
              categories
              materials
            }
          }
        }

        facets {
          sectionKeywords
          responsibilityRoles
        }

        ${specHighlights}
    }
  }
`

//spec details
export const SPEC_DETAILS = gql`
  query GetSpecRequirementDetails($specId: String!, $requirementId: String!) {
    getSpecRequirementDetails(specId: $specId, requirementId: $requirementId) {
      specChildRequirements {
        ${requirements}
      }

      specRequirement {
        ${requirements}
      }
    }

  }
`

export const SEARCH_ORG_SPECS = gql`
  query SearchOrganizationSpecs($input: SearchSpecRequirementsInput, $filters: SearchSpecRequirementsFilters) {
    searchOrganizationSpecs(input: $input, filters: $filters) {
      data {
        ${requirements}
      }
      total
      facets {
        sectionKeywords
        responsibilityRoles
      }
    }
  }
`

export const SEARCH_ORG_SPECS_V2 = gql`
  query SearchOrganizationSpecsV2($input: SearchSpecRequirementsInput, $filters: SearchSpecRequirementsFiltersV2) {
    searchOrganizationSpecsV2(input: $input, filters: $filters) {
      data {
        ${requirements}
      }
      total
      facets {
        sectionKeywords
        responsibilityRoles
        categories {
          categoryId
          subCategoryId
          subCategoryDisplayName
        }
        categoryLengths
        categoryWidths
        categoryHeights
        categoryVolumes
        categoryDiameters
        categoryThicknesses
        materials
        materialCorrosionResistances
        materialFireResistances
        materialColors
        materialFinishes
        materialInsulationLayers
        standards
        locations
        tests
        fluids
        enviromentalLoads
        LODs
        systems
      }
      facetsConfig {
        id
        label
        displayOrder
      }
      ${specHighlights}
    }
  }
`