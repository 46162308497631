import { gql } from "@apollo/client";
import { generalResponse } from "../constants";

export const GET_SANDBOX_MATERIALS = gql`
  query GetMaterials($projectId: ID!, $viewId: ID!, $parentId: String!) {
    getMaterials(id: $projectId, viewId: $viewId, parentId: $parentId) {
        houseId
        viewId
        materials {
          id
          materialName
          materialBMId
          value
          unit
          objectIds
          appliedMaterialName
          appliedMaterialBMId
          isCompoudLayerMaterial
          userCreatedMaterial
        }
    }
  }
`;

export const UPDATE_SANDBOX_MATERIAL = gql`
  mutation UpdateMaterial($houseId: ID!, $viewId: ID!, $input: HouseUpdateMaterialInput!) {
    updateMaterial(id: $houseId, viewId: $viewId, input: $input) {
      ${generalResponse}
    }
  }
`;

export const RESET_SANDBOX_MATERIAL = gql`
  mutation ResetMaterial($materialId: ID!) {
    resetMaterial(materialId: $materialId) {
      ${generalResponse}
    }
  }
`;

export const RESET_SANDBOX_MATERIALS = gql`
  mutation ResetMaterials($projectId: ID!, $viewId: ID!) {
    resetMaterials(id: $projectId, viewId: $viewId) {
      ${generalResponse}
    }
  }
`;

export const SEND_MATERIAL_SANDBOX_EMAIL = gql`
  mutation SendMaterialsUpdateEmail($projectId: ID!, $viewId: ID!, $to: [String]!, $addSelfEmailAsBcc: Boolean!) {
    sendMaterialsUpdateEmail(id: $projectId, viewId: $viewId, to: $to, addSelfEmailAsBCC: $addSelfEmailAsBcc) {
      ${generalResponse}
    }
  }
`;

export const ADD_MATERIAL = gql`
  mutation AddMaterial($houseId: ID!, $viewId: ID!, $input: HouseAddMaterialInput!) {
    addMaterial(id: $houseId, viewId: $viewId, input: $input) {
      ${generalResponse}
    }
  }
`;